import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Headline from '../../Common/Headline';
import Subheadline from '../../Common/Subheadline';
import { useUserData } from '../../userData';
import './paidSuccess.css';

export default function PaidSuccess() {
  const { t } = useTranslation();
  const { userData, setUserData } = useUserData();

  useEffect(() => {
    setUserData({ ...userData, promocode: 'TAXEDF' }, true);
  }, []);

  return (
    <>
      <div className="paid-content">
        <div className="top-section-gradient"></div>
        <div className="paid-headline">
          <div className="success-checkmark">
            <div className="check-icon">
              <span className="icon-line line-tip"></span>
              <span className="icon-line line-long"></span>
              <div className="icon-circle"></div>
              <div className="icon-fix"></div>
            </div>
          </div>
          <Headline noPadding>{t('paidSuccess_headline')}</Headline>
        </div>
        <Subheadline>{t('paidSuccess_body')}</Subheadline>

        <Link to={t('current_language') === 'de' ? '/' : '/en'}>
          <button className="form_submit_btn">{t('paidSuccess_CTA')}</button>
        </Link>
      </div>
    </>
  );
}

const fromEntries = (iterable) =>
  [...iterable].reduce(
    (acc, [key, val]) => ({
      ...acc,
      [key]: val,
    }),
    {}
  );

const traffic_source = () => {
  if (!window) return;
  const referrer = window.document.referrer;

  const params = new URLSearchParams(window.location.search);

  const queries = fromEntries(params.entries());

  let source = referrer;
  if (queries.li_fat_id) source = 'paid social / linkedin';
  else if (queries.gclid) source = 'paid search / google';
  else if (queries.msclkid) source = 'paid search / bing';
  else if (referrer.includes('instagram') && queries.fbclid)
    source = 'paid social / instagram';
  else if (queries.fbclid) source = 'paid social / facebook';
  else if (referrer.includes('instagram'))
    source = 'organic social / instagram';
  else if (referrer.includes('facebook')) source = 'organic social / facebook';
  else if (referrer.includes('reddit')) source = 'organic social / facebook';
  else if (referrer.includes('t.co')) source = 'organic social / twitter';
  else if (referrer.includes('youtube')) source = 'organic social / youtube';
  else if (referrer.includes('linkedin')) source = 'organic social / linkedin';
  else if (referrer.includes('google')) source = 'organic search / google';
  else if (referrer.includes('bing')) source = 'organic search / bing';
  else if (referrer.includes('ecosia')) source = 'organic search / ecosia';
  else if (referrer.includes('duckduckgo'))
    source = 'organic search / duckduckgo';
  else if (referrer.includes('yahoo')) source = 'organic search / yahoo';
  else if (referrer.includes('accountable')) source = 'internal';
  else if (!referrer && !queries.utm_source) source = 'direct';

  return source;
};

export default traffic_source;

import React, { Fragment } from 'react';
import './field.css';
import clsx from 'clsx';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { country } from '../../../../config';
import { BEVATCheckDigit } from '../validators';

const capitalLetterRegex = /([A-Z])/;
const letterRegex = /([a-z])/;
const numberRegex = /([0-9])/;

const Field = React.forwardRef(
  (
    {
      type,
      name,
      label,
      errors,
      options,
      control,
      disabled,
      onBlur,
      value,
      autoComplete,
      autoFocus,
      watch,
      shorter,
    },
    ref
  ) => {
    const { t } = useTranslation();
    const notEmptyValue = watch ? watch(name) : false;
    const password = watch ? watch('password') : false;
    return (
      <div className="field">
        {type === 'picker' ? (
          <Fragment>
            <label className="field_label">{label}</label>
            <Picker
              name={name}
              options={options}
              control={control}
              disabled={disabled}
            />
          </Fragment>
        ) : (
          <div className="magicfield">
            {type === 'sendSMSLink' ? (
              <PhoneInput
                name={name}
                shorter={shorter}
                control={control}
                errors={errors}
                component="sendSMSLink"
              />
            ) : type === 'phone' ? (
              <PhoneInput
                name={name}
                shorter={shorter}
                control={control}
                errors={errors}
              />
            ) : type === 'VATNumber' ? (
              <VATNumberInput name={name} control={control} errors={errors} />
            ) : type === 'select' ? (
              <select
                name={name}
                ref={ref}
                className={clsx(
                  'field_input',
                  'field_input--select',
                  errors[name] && 'field_input--error'
                )}
              >
                {options.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.name}
                  </option>
                ))}
              </select>
            ) : (
              <input
                type={type}
                name={name}
                className={clsx(
                  'field_input',
                  errors[name] && 'field_input--error'
                )}
                ref={ref}
                onBlur={onBlur}
                disabled={disabled}
                defaultValue={value}
                autoComplete={autoComplete}
                autoFocus={autoFocus}
                id={name}
              />
            )}
            <label
              htmlFor={name}
              className={clsx(
                'magicfield_label',
                (notEmptyValue ||
                  type === 'phone' ||
                  type === 'VATNumber' ||
                  (disabled && value)) &&
                  'magicfield_label--filled'
              )}
            >
              {label}
            </label>
          </div>
        )}
        <span className="field_error">
          {errors[name] &&
            (name === 'password' && password ? (
              <>
                {!capitalLetterRegex.test(password) && (
                  <span>{t('password_must_contain_capital')}</span>
                )}
                {!letterRegex.test(password) && (
                  <span>{t('password_must_contain_letters')}</span>
                )}
                {!numberRegex.test(password) && (
                  <span>{t('password_must_contain_numbers')}</span>
                )}
                {errors[name].type === 'minLength' && (
                  <span>{t('password_must_be_six_chars_min')}</span>
                )}
                {errors[name].type === 'maxLength' && (
                  <span>{t('password_must_be_eighty_chars_max')}</span>
                )}
              </>
            ) : (
              <span>
                {errors[name].type === 'required' && t('field_required')}
                {(errors[name].type === 'pattern' ||
                  errors[name].type === 'minLength' ||
                  errors[name].type === 'maxLength') &&
                  t('field_invalid')}
                {errors[name].type === 'validate' &&
                  name === 'email' &&
                  t('email_exists')}
                {errors[name].type === 'validate' &&
                  name === 'VATNumber' &&
                  t('field_invalid')}
              </span>
            ))}
        </span>
      </div>
    );
  }
);

const Picker = ({ options, name, control, disabled }) => {
  const {
    field: { value, onChange },
  } = useController({
    name,
    control,
    rules: { required: true },
    defaultValue: '',
  });
  return (
    <div className="select">
      {options.map((option) => (
        <span
          key={option.value}
          className={clsx(
            'select_option',
            value === option.value && 'select_option--selected'
          )}
          onClick={() => !disabled && onChange(option.value)}
        >
          {option.name}
        </span>
      ))}
    </div>
  );
};

const PhoneInput = ({ name, control, errors, shorter, component }) => {
  const { t } = useTranslation();
  const sessionUserData = JSON.parse(localStorage.getItem('userData'));
  const {
    field: { value, onChange, onBlur },
  } = useController({
    name,
    control,
    rules: { required: true, minLength: 12 },
    defaultValue:
      country === 'de' ||
      sessionUserData.country === 'de' ||
      t('current_language') === 'de'
        ? '🇩🇪 +49 '
        : '🇧🇪 +32 ',
    component,
  });
  const formatPhone = (e) => {
    let phone = e.target.value.replace(/\+(49|32)|[^\d]/g, '');
    const countryCode =
      country === 'de' ||
      sessionUserData.country === 'de' ||
      t('current_language') === 'de'
        ? '🇩🇪 +49 '
        : '🇧🇪 +32 ';
    onChange(
      countryCode +
        phone
          .split('')
          .map(function (char, index) {
            if (
              index === 3 ||
              index === 5 ||
              index === 7 ||
              index === 9 ||
              index === 11
            ) {
              char = ' ' + char;
            }
            if (index > 12) char = '';
            return char;
          })
          .join('')
    );
  };
  return (
    <>
      {component === 'sendSMSLink' ? (
        <input
          type="text"
          name={name}
          className={clsx(
            'field_input--sendSMSLink ',
            shorter && 'field_input--shorter',
            errors[name] && 'field_input--error'
          )}
          value={value}
          onChange={formatPhone}
          onBlur={onBlur}
        />
      ) : (
        <input
          type="text"
          name={name}
          className={clsx(
            'field_input',
            shorter && 'field_input--shorter',
            errors[name] && 'field_input--error'
          )}
          value={value}
          onChange={formatPhone}
          onBlur={onBlur}
        />
      )}
    </>
  );
};

const VATNumberInput = ({ name, control, errors }) => {
  const {
    field: { value, onChange, onBlur },
  } = useController({
    name,
    control,
    rules: {
      pattern: /^BE ([0-1])(\d{3})\.(\d{3})\.(\d{3})$/i,
      validate: BEVATCheckDigit,
    },
    defaultValue: 'BE ',
  });
  const formatVAT = (e) => {
    let vat = e.target.value.toUpperCase().replace(/\+|[^\d]/g, '');
    onChange(
      value.length <= e.target.value.length
        ? 'BE ' +
            vat
              .split('')
              .map(function (char, index) {
                if (index === 4 || index === 7 || index === 10) {
                  char = '.' + char;
                }
                if (index > 9) char = '';
                return char;
              })
              .join('')
        : e.target.value
    );
  };
  return (
    <input
      type="text"
      name={name}
      className={clsx('field_input', errors[name] && 'field_input--error')}
      value={value}
      onChange={formatVAT}
      onBlur={onBlur}
    />
  );
};

export default Field;

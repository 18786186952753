import React, { useEffect } from 'react';
import './loader.css';
import Lottie from 'react-lottie';
import animationData from './heart.json';
import { sendAmplitudeData } from '../../../res/amplitude';

const Loader = ({ subhead, nextStep }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  useEffect(() => {
    setTimeout(() => nextStep(), 3000);
    sendAmplitudeData('WEB_SIGNUP_TABVIEW', {
      tab: 'loader',
      version: 2,
    });
  }, []);

  return (
    <div className="question">
      <span className="question_subhead">{subhead}</span>
      <Lottie options={defaultOptions} height={400} width={400} />
      <br />
    </div>
  );
};
export default Loader;

import { useTranslation } from 'react-i18next';
import { host, webapp } from '../../../config';
import { sendAmplitudeData } from '../../../res/amplitude';
import { getUtms } from '../../../res/utms';
import { useUserData } from '../../../userData';

export const login = async (email, password) => {
  const response = await fetch(`${host}/api/v2/users/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email, password, authContinuation: true }),
  });
  const res = await response.json();

  if (res.auth_continuation_token) return res.auth_continuation_token;
  throw 'No auth continuation token';
};

const GoToWeb = () => {
  const { t } = useTranslation();
  const { userData, setUserData } = useUserData();

  const utms = getUtms();
  const utmString = Object.entries(utms).reduce((string, param) => {
    return string + `&${param[0]}=${param[1]}`;
  }, '');


  const onClick = async (e) => {
    if (!userData.password) return;
    e.preventDefault();
    sendAmplitudeData('WEB_SIGNUP_CLICKEDWEB');
    try {
      const token = await login(userData.email.toLowerCase(), userData.password);
      window &&
        window.location.replace(
          webapp + '/?auth_continuation_token=' + token + utmString
        );
      console.log(token);
    } catch (e) {
      console.log('login error', e);
    }
  };

  return (
    <a
      href="https://web.accountable.eu/"
      className="form_submit_btn--secondary"
      onClick={onClick}
    >
      {t('thanks_web_version')}
    </a>
  );
};

export default GoToWeb;

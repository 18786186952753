import React from 'react';
import './bottombanner.css';
import { useTranslation } from 'react-i18next';
import AppPicDE from './app_de.png';
import AppPicEN from './app_en.png';
import starsPic from './stars.svg';
import clsx from 'clsx';

const testimonials = [
  {
    name: 'review_alias',
    content: 'review_content',
  },
];

const BottomBanner = ({ hide }) => {
  const { t } = useTranslation();
  return (
    <div className={clsx('bottombanner', hide && 'bottombanner--hide')}>
      <div className="bottombanner_bar"></div>
      <div className="bottombanner_content">
        <img
          src={t('current_language') === 'de' ? AppPicDE : AppPicEN}
          alt="App"
          className="bottombanner_app"
        />
        <div>
          <div className="bottombanner_testimonial testimonial">
            <div className="testimonial_head">
              {/* <img
              src={testimonials[0].picture}
              alt={testimonials[0].name}
              className="testimonial_pic"
            /> */}
              <div>
                <span className="testimonial_name">
                  {t(testimonials[0].name)}
                </span>
                <span className="testimonial_platform">
                  {t('on_the')}
                  <a
                    href={t('app_store_link')}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t('app_store')}
                  </a>
                </span>
                <img
                  src={starsPic}
                  alt="5 stars"
                  className="testimonial_stars"
                />
              </div>
            </div>
            <p className="testimonial_content">{t(testimonials[0].content)}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BottomBanner;

import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Field from './Field';
import './question.css';
import './helper.css';
import Lottie from 'react-lottie';
import confettisData from './confettis.json';
import { sendAmplitudeData } from '../../../res/amplitude';
import Button from '../../../Common/Button';
import GoBack from './GoBack';
import fieldHelperIcon from "./helper-icon.svg";
import closeBtn from "./close.svg";

const capitalLetterRegex = /([A-Z])/;
const letterRegex = /([a-z])/;
const numberRegex = /([0-9])/;

const Question = ({
  subhead,
  head,
  button,
  loadingAnimation,
  input,
  nextStep,
  confettis,
  skipable,
  previousStep,
  helperContent
}) => {
  const { t } = useTranslation();
  const { register, handleSubmit, errors, control, watch } = useForm();
  const password = watch('password');
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: confettisData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  useEffect(
    () =>
      sendAmplitudeData('WEB_SIGNUP_TABVIEW', {
        tab: input.name,
        version: 2,
      }),
    []
  );
  const onSubmit = (data) => nextStep(data);
  const formRef = useRef();
  const [showHelper, setShowHelper] = useState(false);
  return (
    <>
      {showHelper &&
        <>
          <div className="helper-wrapper" onClick={() => setShowHelper(false)}></div>
          <div className="helper">
            <div className="helper__content">
              <img src={closeBtn} alt={t('info_close')} onClick={() => setShowHelper(false)} className="helper__close" />
              <span className="helper__head">{head}</span>
              {helperContent}
            </div>
          </div>
        </>}
      {confettis && (
        <div className="confettis">
          <Lottie options={defaultOptions} />
        </div>
      )}
      {previousStep && <GoBack onClick={() => previousStep()} />}
      <form
        ref={formRef}
        className="question"
        onSubmit={handleSubmit(onSubmit)}
      >
        <span className="question_subhead">{subhead}</span>

        <span className="question_head">{head}{helperContent && <span
          role="button"
          title={t("more_info")}
          onClick={() => { setShowHelper(true); sendAmplitudeData("WEB_SIGNUP_CLICKED_HELPER", { helper: input.name ? input.name : '' }); }}
          className="field_helper-icon"
        >
          <img src={fieldHelperIcon} alt={t("more_info")} />
        </span>}</span>

        <Field
          {...input}
          ref={register(input.refParams)}
          errors={errors}
          control={control}
          submit={() =>
            formRef.current.dispatchEvent(
              new Event('submit', { cancelable: true, bubbles: true })
            )
          }
        />
        {skipable && (
          <span
            className="businessselector_nomatch"
            onClick={() => nextStep({})}
          >
            {skipable}
          </span>
        )}
        {input.type !== 'picker' && input.type !== 'dayspicker' && (
          <div className="question_submit">
            <Button biglarge
              isLoading={loadingAnimation === 'loading' ? true : false}
              disabled={loadingAnimation === 'loading' ? true : false}
            >
              {button}
            </Button>
          </div>
        )}
        <div className="field_error">
          {input.name && errors[input.name] && (
            <span>
              {errors[input.name].type === 'required' && (
                <span>{t('field_required')}</span>
              )}
              {console.log(errors[input.name])}
              {console.log(input.name)}
              {input.name === 'password' && password ? (
                <>
                  {!capitalLetterRegex.test(password) && (
                    <span>{t('password_must_contain_capital')}</span>
                  )}
                  {!letterRegex.test(password) && (
                    <span>{t('password_must_contain_letters')}</span>
                  )}
                  {!numberRegex.test(password) && (
                    <span>{t('password_must_contain_numbers')}</span>
                  )}
                  {errors[input.name].type === 'minLength' && (
                    <span>{t('password_must_be_six_chars_min')}</span>
                  )}
                  {errors[input.name].type === 'maxLength' && (
                    <span>{t('password_must_be_eighty_chars_max')}</span>
                  )}
                </>
              ) : (
                (errors[input.name].type === 'pattern' ||
                  errors[input.name].type === 'minLength' ||
                  errors[input.name].type === 'maxLength') && (
                  <span>{t('field_invalid')}</span>
                )
              )}
              {errors[input.name].type === 'validate' &&
                input.name === 'email' && <span>{t('email_exists')}</span>}
              {errors[input.name].type === 'validate' &&
                input.name === 'VATNumber' && <span>{t('field_invalid')}</span>}
            </span>
          )}
        </div>
      </form>
    </>
  );
};
export default Question;

import React, { Fragment, useEffect, useState } from 'react';
import './thankyou.css';
import { useUserData } from '../../userData';
import Headline from '../../Common/Headline';
import { useTranslation } from 'react-i18next';
import AppleStore from './applestore.png';
import GooglePlay from './googleplay.png';
import Phone from './phone.png';
import boldFormatter from '../../res/boldFormatter';
import { sendAmplitudeData } from '../../res/amplitude';
import { country, webapp } from '../../config';
import Field from '../Signup/Form/Field';
import { useForm } from 'react-hook-form';
import { setIntercomPhone } from '../../res/intercom';
import GoToWeb, { login } from './GoToWeb';
import { getUtms } from '../../res/utms';

const ThankYou = () => {
  const { userData } = useUserData();
  const { t } = useTranslation();
  const [textMessageSent, setTextMessageSent] = useState(false);
  const { handleSubmit, errors, control } = useForm({
    mode: 'onBlur',
  });
  const onSubmit = (data) => sendTextMessage(undefined, data);

  const utms = getUtms();
  const utmString = Object.entries(utms).reduce((string, param) => {
    return string + `&${param[0]}=${param[1]}`;
  }, '');

  useEffect(() => {
    const runLogin = async () => {
      if (!userData.password) return;
      await sendAmplitudeData('WEB_SIGNUP_CLICKEDWEB', { method: 'redirect' }, 500, true);
      try {
        const token = await login(userData.email.toLowerCase(), userData.password);
        window &&
          window.location.replace(
            webapp + '/?auth_continuation_token=' + token + utmString
          );
      } catch (e) {
        console.log('login error', e);
      }
    }
    runLogin().catch(console.error);
  }
    , []);
  const sendTextMessage = async (e, data = {}) => {
    if (e) e.preventDefault();
    sendAmplitudeData('WEB_SIGNUP_SENDSMS');
    let params = {
      PHONE: data.phoneNumber || userData.phoneNumber,
      LANGUAGE: t('current_language'),
      api: true,
    };

    setIntercomPhone(
      data.phoneNumber || userData.phoneNumber,
      t('current_language')
    );

    let formData = new FormData();

    for (var k in params) {
      formData.append(k, params[k]);
    }

    const response = await fetch(
      country === 'de'
        ? 'https://accountable.de/danke/'
        : 'https://www.accountable.eu/thanks/',
      {
        method: 'POST',
        body: formData,
      }
    );
    const json = await response.json();
    if (json.ok) setTextMessageSent(true);
  };
  return (
    <div className="thankyou">
      <div className="thankyou__topsection">
        <Headline>
          {t('thanks_congrats')} {userData.firstName}
        </Headline>
        <p className="thankyou-p">
          {t('thanks_account_created')}
        </p>
        {userData.email && (
          <>
            <div className="thankyou-emailaccount">
              <span className="thankyou-emailaccount_email">
                {userData.email}
              </span>
            </div>
          </>
        )}
        {userData.rasoRef && (
          <>
            <br />
            <br />
            <br />
            <p className="thankyou-p thankyou-p--alignleft">
              {boldFormatter(t('thanks_raso_pdf_received'))}
            </p>
            <a
              href={`https://www.accountable.de/r/?t=${userData.rasoRef}&d=1`}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => sendAmplitudeData('WEB_SIGNUP_CLICKEDSHOWPDF')}
            >
              <button className="form_submit_btn form_submit_btn--alt">
                {t('thanks_raso_open_pdf')}
              </button>
            </a>
            <br />
            <br />
            <p className="thankyou-p thankyou-p--alignleft">
              {boldFormatter(t('thanks_raso_next_step'))}
            </p>
          </>
        )}
        <div className="thankyou-download-button thankyou-mobile">
          <a
            href={t('download_app_url')}
            onClick={() => sendAmplitudeData('WEB_SIGNUP_CLICKEDSTORE')}
          >
            <button className="form_submit_btn">
              {t('download_the_app')}
            </button>
          </a>
          <br />
          <img src={Phone} alt="" className="thankyou-phone" />
        </div>
      </div>

      <div className="advantages">
        <div className="advantages__container">
          <h2 className="advantages__header">
            {t('thanks_next_step1_headline')}
          </h2>
          {!textMessageSent ? (
            userData.phoneNumber ? (
              <Fragment>
                <p className="thankyou-p thankyou-p--smaller thankyou-desktop">
                  {t('thanks_text_send', { phone: userData.phoneNumber })}
                </p>

                <button
                  className="thankyou-desktop form_submit_btn"
                  onClick={sendTextMessage}
                >
                  {t('thanks_text_me_the_app')}
                </button>
              </Fragment>
            ) : (
              sendSMSLink()
            )
          ) : (
            <span className="thankyou-smssent">{t('thanks_text_sent')}</span>
          )}
          <div className="stores">
            <a
              href={t('app_store_url')}
              onClick={() =>
                sendAmplitudeData('WEB_SIGNUP_CLICKEDSTORE', {
                  store: 'Apple Store',
                })
              }
              target="_blank"
              rel="noopener noreferrer"
              className="download-accountable-ios"
            >
              <img src={AppleStore} className="stores_store" alt="App store" />
            </a>
            <a
              href={t('google_play_url')}
              onClick={() =>
                sendAmplitudeData('WEB_SIGNUP_CLICKEDSTORE', {
                  store: 'Google Play',
                })
              }
              target="_blank"
              rel="noopener noreferrer"
              className="download-accountable-android"
            >
              <img src={GooglePlay} className="stores_store" alt="Play Store" />
            </a>
          </div>
          <ul className="list-items">
            <li>
              <p className="list-items__header">
                {t('thanks_Step1_bulletpointHeader_1')}
              </p>
              <p className="thankyou-p thankyou-desktop">
                {t('thanks_Step1_bulletpointContent_1')}
              </p>
            </li>
            <li>
              <p className="list-items__header">
                {t('thanks_Step1_bulletpointHeader_2')}
              </p>
              <p className="thankyou-p thankyou-desktop">
                {t('thanks_Step1_bulletpointContent_2')}
              </p>
            </li>
            <li>
              <p className="list-items__header">
                {t('thanks_Step1_bulletpointHeader_3')}
              </p>
              <p className="thankyou-p thankyou-desktop">
                {t('thanks_Step1_bulletpointContent_3')}
              </p>
            </li>
          </ul>
        </div>
        <div className="advantages__container">
          <h2 className="advantages__header">
            {t('thanks_next_step2_headline')}
          </h2>
          <GoToWeb />
          <ul className="list-items">
            <li>
              <p className="list-items__header">
                {t('thanks_Step2_bulletpointHeader_1')}
              </p>
              <p className="thankyou-p thankyou-desktop">
                {t('thanks_Step2_bulletpointContent_1')}
              </p>
            </li>
            <li>
              <p className="list-items__header">
                {t('thanks_Step2_bulletpointHeader_2')}
              </p>
              <p className="thankyou-p thankyou-desktop">
                {t('thanks_Step2_bulletpointContent_2')}
              </p>
            </li>
            <li>
              <p className="list-items__header">
                {t('thanks_Step2_bulletpointHeader_3')}
              </p>
              <p className="thankyou-p thankyou-desktop">
                {t('thanks_Step2_bulletpointContent_3')}
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );

  function sendSMSLink() {
    return (
      <div className="thankyou-desktop">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="thanks_sensmslink_Form"
        >
          <Field
            type="sendSMSLink"
            name="phoneNumber"
            errors={errors}
            control={control}
            shorter={true}
          />
          <button className="form_submit_btn form_submit_btn--lower sendsmslink_Button">
            {t('thanks_text_me_the_app')}
          </button>
        </form>
      </div>
    );
  }
};
export default ThankYou;

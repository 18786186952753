import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { sendAmplitudeData } from '../../../res/amplitude';
import './businessselector.css';

const Question = ({ subhead, businessInfoList, nextStep }) => {
  const { t } = useTranslation();
  useEffect(
    () =>
      sendAmplitudeData('WEB_SIGNUP_TABVIEW', {
        tab: 'selectBusiness',
        version: 2,
      }),
    []
  );
  return (
    <div className="question">
      <span className="question_subhead">{subhead}</span>
      <span
        className="businessselector_nomatch"
        onClick={() => {
          nextStep({});
          sendAmplitudeData('WEB_SIGNUP_CLICKEDMANUALLYSETVAT');
        }}
      >
        {t('I_can_find_my_business')}
      </span>
      {businessInfoList.map((b, i) => (
        <div
          className="businessselector"
          key={b.VATNumber}
          onClick={() => {
            nextStep(b);
            sendAmplitudeData('WEB_SIGNUP_CLICKEDSELECTBUSINESS', {
              position: i,
            });
          }}
        >
          <span className="businessselector_head">
            {b.name} - {b.VATNumber}
          </span>
          {b.street}
          <span className="businessselector_content">
            {b.zipcode} {b?.city?.toUpperCase()}
          </span>
        </div>
      ))}
    </div>
  );
};
export default Question;

import clsx from "clsx";
import React from "react";
import "./headline.css";

const Headline = ({ children, centered, left, noPadding }) => (
  <h1
    className={clsx(
      "headline",
      centered && "headline--center",
      left && "headline--left",
      noPadding && "headline--noPadding"
    )}
  >
    {children}
  </h1>
);

export default Headline;

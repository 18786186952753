
import { useTranslation } from 'react-i18next';
import PrettyInvoice from './pretty-invoice.svg';
import BasicInvoice from './basic-invoice.svg';

import './invoicing.css';
import Button from '../../Common/Button';
import Form from '../Raso/Form';
import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { sendAmplitudeData } from '../../res/amplitude';

const Invoicing = () => {
  const { t } = useTranslation();
  const [showPasswordForm, setShowPasswordForm] = useState(false);
  const [experiments, setExperiments] = useState({});
  useEffect(() => {
    try {
      const lstorage = localStorage.getItem('xprmt');
      let xprmt = JSON.parse(lstorage);
      if (!xprmt || !xprmt.hasOwnProperty('id002')) {
        xprmt = { ...xprmt, id002: Math.floor(Math.random() * 2) };
      }
      setExperiments(xprmt);
      localStorage.setItem('xprmt', JSON.stringify(xprmt));
      sendAmplitudeData('WEB_SIGNUP_EXPERIMENTS', xprmt);
    } catch (e) { console.log(e); }
  }, []);
  useEffect(() => {
  }, [experiments]);


  return (
    <div className="invoicing">
      {showPasswordForm &&
        <>
          <div className="invocing-modal-wrapper"></div>
          <div className="invoicing-modal">
            <div className="invoicing-modal_form">
              <Form />
            </div>
          </div>
        </>
      }

      <div className={clsx("invoicing_container", showPasswordForm && "invoicing_container--dark")}>
        <div className="thankyou__topsection">
          <h1 className="invoicing_head">
            {t('invoicing_headline')}
          </h1>
          <p className="invoicing_subhead">
            {t('invoicing_subheadline')}
          </p>
        </div>
        <div className='invoicing_flex'>

          <img src={PrettyInvoice} alt="Pretty" className="invoicing_invoice" />
          <div className="invocing_flexcontent">
            <h2 className="invoicing_h2">{t('invoicing_create_your_account')}</h2>
            <ul className="invoicing_ul">
              <li><span className="invoicing_ul__head">{t('invoicing_create_your_account_b1')}</span>
                {t('invoicing_create_your_account_e1')}
              </li>
              <li><span className="invoicing_ul__head">{t('invoicing_create_your_account_b2')}</span>
                {t('invoicing_create_your_account_e2')}
              </li>
              <li><span className="invoicing_ul__head">{t('invoicing_create_your_account_b3')}</span>
                {t('invoicing_create_your_account_e3')}
              </li>
              <li><span className="invoicing_ul__head">{t('invoicing_create_your_account_b4')}</span>
                {t('invoicing_create_your_account_e4')}
              </li>
              <li><span className="invoicing_ul__head">{t('invoicing_create_your_account_b5')}</span>
                {t('invoicing_create_your_account_e5')}
              </li>
            </ul>
            <Button classic func={() => setShowPasswordForm(true)}>{t('invoicing_set_password')}</Button>
          </div>
          {!(experiments && experiments.id002) ?
            <>
              <span className="invoicing_bar" />
              <img src={BasicInvoice} alt="Basic" className="invoicing_invoice" />
            </>
            : <div style={{ flexGrow: 1 }}></div>}
        </div>
      </div>
    </div >
  )
}

export default Invoicing;
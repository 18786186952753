import clsx from 'clsx';
import React from 'react';
import './progressbar.css';

const ProgressBar = ({ step, progressbarSteps, labels, nextStep }) => {
  return (
    <div className="progressbar">
      {labels.map((label, i) => (
        <div
          className="progressbar_step"
          key={i}
        >
          <span
            className={clsx(
              'progressbar_bar',
              i - 1 < step && 'progressbar_bar--active'
            )}
          ></span>
          <span
            className={clsx(
              'progressbar_label',
              i - 1 < step && 'progressbar_label--active'
            )}
          >
            {label}
          </span>
        </div>
      ))}
    </div>
  );
};
export default ProgressBar;

import { amplitudeKey } from '../config';
import * as amplitude from '@amplitude/analytics-browser';


export const initAmplitude = () => {
  amplitude.init(amplitudeKey);
};

export const setAmplitudeUserId = async (userId, timeout = 500) => {
  if (amplitude.getSessionId()) {
    await amplitude.setUserId(userId);
  } else {
    await new Promise(resolve => setTimeout(async () => {
      await setAmplitudeUserId(userId, timeout * 2);
      resolve();
    }, timeout));
  }
};

export const setAmplitudeUserProperties = async (properties, timeout = 500) => {
  const identifyObj = new amplitude.Identify();
  if (amplitude.getSessionId()) {
    for await (const [key, value] of Object.entries(properties)) {
      identifyObj.set(key, value);
    }
    await amplitude.identify(identifyObj);
  } else {
    await new Promise(resolve => setTimeout(async () => {
      await setAmplitudeUserProperties(properties, timeout * 2);
      resolve();
    }, timeout));
  }
};

export const sendAmplitudeData = async (
  eventType,
  eventProperties = {},
  timeout = 500,
  flush
) => {
  if (amplitude.getSessionId()) {
    console.log('track', eventType);
    await amplitude.track(eventType, eventProperties);
    if (flush) {
      console.log('flux start', eventType);
      await amplitude.flush().promise;
      console.log('flux', eventType);
    }
  } else {
    await new Promise(resolve => setTimeout(async () => {
      await sendAmplitudeData(eventType, eventProperties, timeout * 2, flush);
      resolve();
    }, timeout));
  }
};

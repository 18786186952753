import './App.css';
import { Switch, Route, withRouter, useLocation } from 'react-router-dom';
import Signup from './Pages/Signup';
import ThankYou from './Pages/ThankYou';
import { UserDataProvider } from './userData';
import { Fragment, useEffect } from 'react';
import { initIntercom } from './res/intercom';
import {
  initAmplitude,
  sendAmplitudeData,
  setAmplitudeUserProperties,
} from './res/amplitude';
import { initPixel, logPageView } from './res/pixel';
import { initGTM } from './res/gtag';
import { country } from './config';
import { FAlogEvent } from './res/fpc';
import Onboarding from './Pages/Onboarding/index.legacy';
import RASO from './Pages/Raso';
import { getUtms } from './res/utms';
import { initTapfiliate } from './res/tapfiliate';
import traffic_source from './res/traffic_source';
import { useState } from 'react';
import PaidSuccess from './Pages/PaidSuccess/PaidSuccess';
import './variables.css';
import Invoicing from './Pages/Invoicing';
import NotFound from './Pages/NotFound';

function App() {
  const [firstReload, setFirstReload] = useState(true);
  useEffect(() => {
    initIntercom();
    initAmplitude();
    initPixel();
    initGTM();
    initTapfiliate();
    if (country === 'de') FAlogEvent();
  }, []);

  const location = useLocation();
  useEffect(() => {
    logPageView();

    const utms = getUtms();
    const referrer_url = window.document.referrer;
    sendAmplitudeData('WEB_SIGNUP_PAGEVIEW', {
      path: location.pathname,
      country,
      ...utms,
      referrer_url,
      ...(firstReload && { source: traffic_source() }),
    });
    setAmplitudeUserProperties({
      utms,
      ...utms,
      referrer_url,
      user_country: country,
    });
    setFirstReload(false);
  }, [location.pathname, location.search]);

  return (
    <UserDataProvider>
      <Switch>
        {country === 'be' ? (
          <Fragment>
            <Route exact path="/" component={Onboarding} />
            <Route exact path="/en" component={Onboarding} />
            <Route exact path="/nl" component={Onboarding} />
            <Route exact path="/fr" component={Onboarding} />
            <Route exact path="/de" component={Onboarding} />
            <Route exact path="/de/thank-you" component={ThankYou} />
            <Route exact path="/en/thank-you" component={ThankYou} />
            <Route exact path="/nl/bedankt" component={ThankYou} />
            <Route exact path="/fr/merci" component={ThankYou} />
            <Route exact path="/thank-you" component={ThankYou} />
            <Route exact path="/onboarding" component={Onboarding} />
            <Route exact path="/en/onboarding" component={Onboarding} />
            <Route exact path="/fr/onboarding" component={Onboarding} />
            <Route exact path="/nl/onboarding" component={Onboarding} />
            <Route exact path="/invoicing" component={Invoicing} />
            <Route exact path="/fr/invoicing" component={Invoicing} />
            <Route exact path="/nl/invoicing" component={Invoicing} />
            <Route exact path="/en/invoicing" component={Invoicing} />
            <Route path="*" component={NotFound} />
          </Fragment>
        ) : (
          <Fragment>
            <Route exact path="/" component={Onboarding} />
            <Route exact path="/en" component={Onboarding} />
            <Route exact path="/nl" component={Onboarding} />
            <Route exact path="/fr" component={Onboarding} />
            <Route exact path="/en/thank-you" component={ThankYou} />
            <Route exact path="/nl/bedankt" component={ThankYou} />
            <Route exact path="/fr/merci" component={ThankYou} />
            <Route exact path="/thank-you" component={ThankYou} />
            <Route exact path="/onboarding" component={Onboarding} />
            <Route exact path="/en/onboarding" component={Onboarding} />
            <Route exact path="/fr/onboarding" component={Onboarding} />
            <Route exact path="/nl/onboarding" component={Onboarding} />
            <Route exact path="/raso" component={RASO} />
            <Route exact path="/en/raso" component={RASO} />
            <Route exact path="/erfolgreichbezahlt" component={PaidSuccess} />
            <Route exact path="/en/invoicing" component={Invoicing} />
            <Route exact path="/invoicing" component={Invoicing} />
            <Route path="*" component={NotFound} />
          </Fragment>
        )}
      </Switch>
    </UserDataProvider>
  );
}

export default withRouter(App);

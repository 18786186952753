import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { sendAmplitudeData } from '../../res/amplitude';

const BottomBanner = ({ hide }) => {
  const location = useLocation();
  useEffect(() => {
    sendAmplitudeData('WEB_SIGNUP_404', {
      path: location.pathname,
    });
  }, [location.pathname]);
  return (
    <div >
      Not found
    </div>
  );
};
export default BottomBanner;

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEN from './res/locales/en/translations.json';
import translationDE from './res/locales/de/translations.json';
import translationNL from './res/locales/nl/translations.json';
import translationFR from './res/locales/fr/translations.json';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: translationEN,
  },
  de: {
    translation: translationDE,
  },
  nl: {
    translation: translationNL,
  },
  fr: {
    translation: translationFR,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    resources,
    whitelist: ['en', 'de', 'nl', 'fr'],
    fallbackLng: [process.env.REACT_APP_DEFAULTLANGUAGE],
    detection: {
      order: ['path'],
      lookupFromPathIndex: 0,
    },
    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;

import React, { Fragment } from 'react';
import './field.css';
import clsx from 'clsx';
import { useController } from 'react-hook-form';
import { country } from '../../../../config';
import { BEVATCheckDigit } from '../../../Signup/Form/validators';
import { sendAmplitudeData } from '../../../../res/amplitude';
import { useTranslation } from 'react-i18next';

const Field = React.forwardRef(
  (
    {
      type,
      name,
      placeholder,
      errors,
      options,
      control,
      disabled,
      onBlur,
      value,
      autoComplete,
      submit,
    },
    ref
  ) => {
    return (
      <div className="field">
        {type === 'picker' || type === 'dayspicker' ? (
          <Fragment>
            <Picker
              name={name}
              options={options}
              control={control}
              disabled={disabled}
              submit={submit}
              type={type}
            />
          </Fragment>
        ) : (
          <div>
            {type === 'phone' ? (
              <PhoneInput name={name} control={control} errors={errors} />
            ) : type === 'VATNumber' ? (
              <VATNumberInput name={name} control={control} errors={errors} />
            ) : type === 'select' ? (
              <select
                name={name}
                ref={ref}
                className={clsx(
                  'field_input',
                  'field_input--select',
                  errors[name] && 'field_input--error'
                )}
              >
                {options.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.name}
                  </option>
                ))}
              </select>
            ) : (
              <input
                type={type}
                name={name}
                className={clsx(
                  'question_input',
                  errors[name] && 'field_input--error'
                )}
                ref={ref}
                onBlur={onBlur}
                disabled={disabled}
                defaultValue={value}
                autoComplete={autoComplete}
                autoFocus={true}
                placeholder={placeholder}
              />
            )}
          </div>
        )}
      </div>
    );
  }
);

const Picker = ({ options, name, control, disabled, submit, type }) => {
  const {
    field: { value, onChange },
  } = useController({
    name,
    control,
    rules: { required: true },
    defaultValue: '',
  });
  return (
    <Fragment>
      <div className="questionselect">
        {options.map(
          (option) =>
            !option.skipper && (
              <div
                key={option.value}
                className={clsx(
                  'questionselect_option',
                  type === 'dayspicker' && 'questionselect_option--smaller',
                  value === option.value && 'questionselect_option--selected'
                )}
                onClick={() =>
                  (!disabled && onChange(option.value)) || submit()
                }
              >
                <div>
                  <span className="question_select_option_name">
                    {option.name}
                  </span>
                  {option.description && (
                    <>
                      <br />
                      <span className="question_select_option_desc">
                        {option.description}
                      </span>
                    </>
                  )}
                </div>
              </div>
            )
        )}
      </div>
      {options.map(
        (option) =>
          option.skipper && (
            <span
              key={option.value}
              className="businessselector_nomatch"
              onClick={() =>
                (!disabled && onChange(option.value)) ||
                submit() ||
                sendAmplitudeData('WEB_SIGNUP_CLICKEDSKIP', {
                  tab: name,
                  version: 2,
                })
              }
            >
              {option.name}
            </span>
          )
      )}
    </Fragment>
  );
};

const PhoneInput = ({ name, control, errors }) => {
  const sessionUserData = JSON.parse(localStorage.getItem('userData'));
  const { t } = useTranslation();
  const {
    field: { value, onChange, onBlur },
  } = useController({
    name,
    control,
    rules: { required: true, minLength: 12 },
    defaultValue:
      country === 'de' ||
        sessionUserData.country === 'de' ||
        t('current_language') === 'de'
        ? '+49 '
        : '+32 ',
  });
  const formatPhone = (e) => {
    let phone = e.target.value.replace(/\+|[^\d]/g, '');
    onChange(
      '+' +
      phone
        .split('')
        .map(function (char, index) {
          if (
            index === 2 ||
            index === 5 ||
            index === 7 ||
            index === 9 ||
            index === 11
          ) {
            char = ' ' + char;
          }
          if (index > 12) char = '';
          return char;
        })
        .join('')
    );
  };
  return (
    <input
      type="text"
      name={name}
      className={clsx('field_input', errors[name] && 'field_input--error')}
      value={value}
      onChange={formatPhone}
      onBlur={onBlur}
    />
  );
};

const VATNumberInput = ({ name, control, errors }) => {
  const {
    field: { value, onChange, onBlur },
  } = useController({
    name,
    control,
    rules: {
      pattern: /^BE ([0-1])(\d{3})\.(\d{3})\.(\d{3})$/i,
      validate: BEVATCheckDigit,
    },
    defaultValue: 'BE ',
  });
  const formatVAT = (e) => {
    let vat = e.target.value.toUpperCase().replace(/\+|[^\d]/g, '');
    onChange(
      value.length <= e.target.value.length
        ? 'BE ' +
        vat
          .split('')
          .map(function (char, index) {
            if (index === 4 || index === 7 || index === 10) {
              char = '.' + char;
            }
            if (index > 9) char = '';
            return char;
          })
          .join('')
        : e.target.value
    );
  };
  return (
    <input
      type="text"
      name={name}
      className={clsx('question_input', errors[name] && 'field_input--error')}
      value={value}
      onChange={formatVAT}
      autoFocus={true}
      onBlur={onBlur}
    />
  );
};

export default Field;

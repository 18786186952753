import React, { Fragment } from 'react';
import Form from './Form';
import './raso.css';
import BottomBanner from '../Onboarding/BottomBanner'

const Signup = () => {
  return (
    <Fragment>
    <div className="raso" />
    <div className="raso-content">
        <div className="raso-content_form">
          <Form />
        </div>
        <BottomBanner />
    </div>
    </Fragment>
  );
};
export default Signup;

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import './i18n';
import * as firebase from "firebase/app";

firebase.initializeApp({
  apiKey: "AIzaSyBwezoMmR1TDRCtvgP6e4dwypQJ3Pm3Meg",
  authDomain: "fbase.accountable.eu",
  projectId: "accountable-onboarding-3f387",
  storageBucket: "fbase.accountable.eu",
  messagingSenderId: "605267227361",
  appId: "1:605267227361:web:a90de973ea008a355dbda0"
});

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
);

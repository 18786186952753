import { useTranslation } from 'react-i18next';
import GoBackIcon from './arrow-left.svg';
import './goback.css';

const GoBack = ({ onClick }) => {
  const { t } = useTranslation();
  return (
    <span onClick={onClick} className="goback"><img src={GoBackIcon} alt="" className="goback__icon" />{t('go_back')}</span>)
}

export default GoBack;
import { GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth";
import { useTranslation } from 'react-i18next';
import GoogleIcon from './google.svg';

import './google-btn.css';
import { useState } from "react";
import { emailValidator } from "../../Signup/Form/validators";
import { sendAmplitudeData } from "../../../res/amplitude";

const GoogleBtn = ({ nextStep }) => {
  const provider = new GoogleAuthProvider();
  const { t } = useTranslation();
  const [emailExists, setEmailExists] = useState(false);

  const loginWithGoogle = (e) => {
    sendAmplitudeData('WEB_SIGNUP_CLICKEDGOOGLESIGNUP');
    e.preventDefault()
    const auth = getAuth();
    setEmailExists(false);
    signInWithPopup(auth, provider)
      .then(async (result) => {
        const user = result.user;
        if (result.user.email) {

          console.log('user: ', user);

          if (! await emailValidator(result.user.email)) {
            setEmailExists(true);
            sendAmplitudeData('WEB_SIGNUP_SIGNUPWITHGOOGLE', { status: 'exits' });
            return;
          }
          sendAmplitudeData('WEB_SIGNUP_SIGNUPWITHGOOGLE', { status: 'new' });
          nextStep({ email: result.user.email });
        }
      }).catch((error) => {
        console.log('error: ', error);
      });

  }


  return (
    <>
      <button
        onClick={loginWithGoogle}
        className='google-btn'
      >
        <img src={GoogleIcon} alt="" className="google-btn__icon" />
        {t('continue_with_google')}

      </button>
      {emailExists &&
        <div className="google-btn__error">
          {t('email_exists')}
        </div>
      }
    </>);
}

export default GoogleBtn;
